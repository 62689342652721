<template>
  <div class="container mt-5">
    <div class="row d-flex justify-content-center">
      <div class="col-md-6">
        <div class="card px-5 py-5" id="form1">
          <div class="form-data" v-if="!submitted">
            <div class="forms-inputs mb-4"><span>Email</span>
              <input autocomplete="off" type="text" v-model="email" v-bind:class="{'form-control':true, 'is-invalid' : !validEmail(email) && emailBlured}" v-on:blur="emailBlured = true">
              <div class="invalid-feedback">Не корректный Email!</div>
            </div>
            <div class="forms-inputs mb-4"><span>Пароль</span>
              <input autocomplete="off" type="password" v-model="password" v-bind:class="{'form-control':true, 'is-invalid' : !validPassword(password) && passwordBlured}" v-on:blur="passwordBlured = true">
              <div class="invalid-feedback">Не менее 6-ти символов!</div>
            </div>
            <div class="mb-3">
              <button v-on:click.stop.prevent="submit" class="btn btn-dark w-100">Войти</button>
            </div>
          </div>
          <div class="success-data" v-else>
            <div class="text-center d-flex flex-column"><i class='bx bxs-badge-check'></i> <span
                class="text-center fs-1">Вы успешно авторизовались</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data: function () {
    return {
      email: "",
      emailBlured: false,
      valid: false,
      submitted: false,
      password: "",
      passwordBlured: false
    }
  },
  methods: {
    validate() {
      this.emailBlured = true;
      this.passwordBlured = true;
      if (this.validEmail(this.email) && this.validPassword(this.password)) {
        this.valid = true;
      }
    },
    validEmail(email) {
      let re = /(.+)@(.+){2,}\.(.+){2,}/;
      if (re.test(email.toLowerCase())) {
        return true;
      }
    },
    validPassword(password) {
      if (password.length > 5) {
        return true;
      }
    },
    submit() {
      this.validate();
      if (!this.valid)
        return;

      this.$store.dispatch('Auth/login', {
          login: this.email,
          password: this.password
      }).then(() => {
        this.$router.push({ path: '/dashboard' });
        this.$bvToast.toast('Вы успешно авторизовались', {
          title: 'Успех',
          autoHideDelay: 5000,
          variant: 'success',
          appendToast: true
        });
      });
    }
  }
}
</script>

<style scoped>
.card {
  border: none;
  height: 320px
}

.forms-inputs {
  position: relative
}

.forms-inputs span {
  position: absolute;
  top: -18px;
  left: 10px;
  background-color: #fff;
  padding: 5px 10px;
  font-size: 15px
}

.forms-inputs input {
  height: 50px;
  border: 2px solid #eee
}

.forms-inputs input:focus {
  box-shadow: none;
  outline: none;
  border: 2px solid #000
}

.btn {
  height: 50px
}

.success-data {
  display: flex;
  flex-direction: column
}

.bxs-badge-check {
  font-size: 90px
}
</style>